import React,{useEffect,useState} from "react"
import DefaultLayout from "../layouts/DefaultLayout"
import { setPageTitle } from "../store/resourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import { AlertWarning,AlertError } from "../components/Alerts"
export default function ProjectReport(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()
    const reportView = useSelector((state) => state.groupsData.reportView);
    const [report, SetReport] = useState("")
    
    useEffect(() => {
        if(!id){
            AlertWarning('Please select a project')
            return navigate("/projects");
        }
        dispatch(setPageTitle("Project Report"));
        let reportInfo = reportView.filter(function (report) { return report.project_id === id })
        if(reportInfo.length === 0){
            AlertError('Report not found')
            return navigate("/projects");
            
        }
        SetReport(reportInfo[0].report_text)
        // eslint-disable-next-line
    }, [dispatch,id,reportView]);
    return(
        <DefaultLayout>
            <div className="container-fluid">
            <div className="contant-section">
                <div className="queans">
                    <div className="row form-center justify-content-center align-items-center">
                        <div className="col-xl-12 col-md-12">
                        <div className="generate-report"
                              dangerouslySetInnerHTML={{
                                __html: report
                              }}
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </DefaultLayout>
    )
}