import React,{useEffect, useState} from "react"
import DefaultLayout from "../layouts/DefaultLayout"
import { setPageTitle } from "../store/resourceSlice";
import { useDispatch } from "react-redux";
import { postData } from '../services/HttpServices'
import { useForm } from 'react-hook-form';
import { AlertSuccess, AlertError } from "../components/Alerts";
import { useNavigate } from "react-router-dom";
import { Reveal } from 'react-reveal';
import { Link } from "react-router-dom";

export default function CreateProject({backButton,setIntialStepfn}){

    
    const dispatch = useDispatch();
   const navigate = useNavigate()
     const [formValues, setFormValues] = useState([]);
     const [currentStep, setCurrentStep] = useState(1);
     const [industry, setIndustry] = useState("");
     const [technologies, setTechnologies] = useState([]);
     const [subIndustry, setSubIndustry] = useState([]);
     // eslint-disable-next-line
    const { register, watch, handleSubmit, setFocus, formState: { errors },setError,clearErrors,getValues } = useForm();

    const onSubmit = data => {
        if(!getValues('technology')){
            setError('technology',{type:'custom',message:"Please select technology"});
                return
        }
        clearErrors('technology');
         data.industry = data.sub_industry
        delete data.sub_industry
        postData('/projects/manage',JSON.stringify(data)).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
            
                if(responseData.status === 1){
                    AlertSuccess(responseData.data.success)
                    navigate("/project-details/"+responseData.data.project_id);
               
                }else{
                    AlertError(responseData.data.error)
                }
            }
        })

       
    }
    const getData=()=>{
        postData('/projects/getformvals').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    setFormValues(responseData.data.industry)
                    setTechnologies(responseData.data.technology)
                }

            }
        })
    }
    const goToStep = (step) => {
        if(step === 1){
            setIntialStepfn(false)
        }
        if(step === 2){
           
            if(!document.getElementById("project_name").value){
                setError('project_name',{type:'custom',message:"Enter project name or client name"});
                return
            }
            clearErrors('project_name');
            setIntialStepfn(true)
        }
        if(step === 6){
            const sub_industry = formValues.filter(function (formValue) { return formValue.id === getValues('industry') })
            setIndustry(sub_industry[0].industry)
            setSubIndustry(sub_industry[0].child)
        }
        setCurrentStep(step)
    }

   
    useEffect(() => {
        dispatch(setPageTitle("Create Projects"));
        getData()
        // eslint-disable-next-line
    }, [dispatch]);

    const IndustryHandler = (value) => {
        if(!value){
            setError('industry',{type:'custom',message:"Please select industry"});
            return
        }
        clearErrors('industry');
        goToStep(6)
    }

   useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
          return !!errors[field] ? field : a;
        }, null);
      
        if (firstError) {
          setFocus(firstError);
        }
      }, [errors, setFocus]);
      const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
      };
      const checkDirtyFileds = () => {

        const watchAllFields = watch() 
        const iterator = Object.values(watchAllFields).filter(function(a){
            return a ? true: false
        });
         if (iterator.length > 0) {
            backButton(true)
        }else{
           backButton(false)
        }
       }
    return(
        <DefaultLayout showQuestionBar={false}>
        <div className="container-fluid ">
        <div className="row"  >
            <div className="col-12">
            <div className="back-float">
                        <Link  to={"#"} type="button" onClick={()=> { checkDirtyFileds()}}> <i className="bi bi-x-octagon"></i></Link>
                </div>

            </div>
        </div>
        <div className="row">
                    <div className="col-xl-12 col-md-12  d-flex ">
                        {currentStep > 1 &&
                    <div className="line_box stav_projektu" style={{marginTop: "40px", marginBottom:"40px"}}>
                        
                            <div className={getValues('project_name') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                    <h4>{'Project Name'}</h4>
                                    <p>{getValues('project_name') ? getValues('project_name'):''}</p>
                                </div>
                                <Link  onClick={()=> currentStep > 1 && goToStep(1)} className="tvar">
                                    { getValues('project_name')  &&
                                         <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                            <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                             <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                             <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                             <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                            </g>
                                         </svg>
                                     }
                                    </Link>
                            </div>
                            <div className={getValues('website') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                     <h4>{'Website'}</h4>
                                    <p>{getValues('website') ? getValues('website'):''}</p>
                                </div>
                                <Link  onClick={()=> currentStep > 2 && goToStep(2)} className="tvar">
                                { getValues('website') &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                    <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                     <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                     <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                     <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                    </g>
                                 </svg>
                                }
                                    
                                    </Link>
                            </div>
                            <div className={getValues('description') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                <h4>{'Description'}</h4>
                                    <p>{getValues('description') ? getValues('description').substring(0, 30)+'...':''}</p>
                                </div>
                                <Link onClick={()=> currentStep > 3 && goToStep(3)} className="tvar"> { getValues('description') &&
                                   <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                   <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                    <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                    <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                    <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                   </g>
                                </svg>
                                }</Link>
                            </div>
                            <div className={ getValues('requirements') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                <h4>{'Requirements'}</h4>
                                    <p>{getValues('requirements') ? getValues('requirements').substring(0, 30)+'...':''}</p>
                                </div>
                                <Link  onClick={()=> currentStep > 4 && goToStep(4)} className="tvar"> { getValues('requirements') &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                    <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                     <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                     <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                     <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                    </g>
                                 </svg>
                                }</Link>
                            </div>
                            <div className={getValues('industry') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                    <h4>{'Industry'}</h4>
                                    <p>{getValues('industry') ? industry:''}</p>
                                </div>
                                <Link  onClick={()=> currentStep > 5 && goToStep(5)} className="tvar">{ getValues('industry') &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                    <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                     <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                     <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                     <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                    </g>
                                 </svg>
                                }</Link>
                            </div>
                            <div className={getValues('technology') ? 'text_circle done': 'text_circle'}>
                                <div className="circle">
                                    <h4>{'Technology'}</h4>
                                    <p>{getValues('technology') ? industry:''}</p>
                                </div>
                                <Link onClick={()=> currentStep > 6 && goToStep(6)} className="tvar">{ getValues('technology') &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-success" viewBox="0 0 24 24">
                                    <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                     <circle className="success-circle-outline" cx="12" cy="12" r="11.5"></circle>
                                     <circle className="success-circle-fill" cx="12" cy="12" r="11.5"></circle>
                                     <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13"></polyline>
                                    </g>
                                 </svg>
                                }</Link>
                            </div>
                            </div>
                            }
                      
                    </div>
                </div>
                <div className="row form-center justify-content-center align-items-center form-column">
                    <div className="col-xl-12 col-md-12 d-flex flex-column align-items-center justify-content-center">
                  
                        <form id="msform" onSubmit={handleSubmit(onSubmit)} name="msform">
                            <fieldset id="step1" style={currentStep === 1? {}:{visibility:'hidden'}}>
                              <Reveal effect="slide-in-right">
                                <div className="mb-3">
                                    <label htmlFor="project_name" className="form-label">Enter Project or Client Name</label>
                                    <input type="text" className="form-control" id="project_name" name="project_name" placeholder="Type here" {...register("project_name", {required: true})} onKeyDown={(e) => checkKeyDown(e)} />
                                    {errors?.project_name &&  <div className="text-danger">{errors.project_name.message}</div>}
                                    <button type="button" className="next action-button btn-next" onClick={()=>goToStep(2)}>Next
                                    <span><i className="bi bi-arrow-right"></i></span>
                                </button>
                                </div>
                               
                                </Reveal>
                               
                            </fieldset>
                            <fieldset  id="step2" style={currentStep === 2? {}:{visibility:'hidden'}}>
                                <Reveal effect="slide-in-right">
                                <div className="mb-3">
                                    <label htmlFor="website" className="form-label">Enter Website URL <span>(Optional)</span></label>
                                    <input type="text" className="form-control" id="website" name="website" placeholder="Enter URL"  {...register("website", {required: false})} onKeyDown={(e) => checkKeyDown(e)} />
                                    {errors?.website &&  <div className="text-danger">{errors.website.message}</div>}
                                    <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(1)}><span><i className="bi bi-arrow-left"></i></span> Back
                                    </button>
                                    <button type="button" className="next action-button btn-next" onClick={()=>goToStep(3)}>Next
                                        <span><i className="bi bi-arrow-right"></i></span>
                                    </button>
                                </div>
                               
                                </Reveal>
                                
                            </fieldset>
                            <fieldset style={currentStep === 3? {}:{visibility:'hidden'}}>
                              <Reveal effect="slide-in-right">
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <input type="text" className="form-control" id="description" name="description" placeholder="Description" {...register("description", {required: false})} onKeyDown={(e) => checkKeyDown(e)}/>
                                    {errors?.description &&  <div className="text-danger">{errors.description.message}</div>}
                                    <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(2)}>Back
                                    <span><i className="bi bi-arrow-left"></i></span>
                                    </button>
                                    <button type="button" className="next submit action-button" onClick={()=>goToStep(4)}>Next
                                        <span><i className="bi bi-arrow-right"></i></span>
                                    </button>
                                </div>
                             
                                </Reveal>
                               
                            </fieldset>
                            <fieldset style={currentStep === 4? {}:{visibility:'hidden'}}>
                                <Reveal effect="slide-in-right">
                                <div className="mb-3">
                                    <label htmlFor="requirements" className="form-label">Requirements</label>
                                    <input type="text" className="form-control" id="requirements" name="requirements" placeholder="requirements" {...register("requirements", {required: false})} onKeyDown={(e) => checkKeyDown(e)} />
                                    {errors?.requirements &&  <div className="text-danger">{errors.requirements.message}</div>}
                                    <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(3)}>Back
                                    <span><i className="bi bi-arrow-left"></i></span>
                                    </button>
                                    <button type="button" className="next submit action-button" onClick={()=>goToStep(5)}>Next
                                        <span><i className="bi bi-arrow-right"></i></span>
                                   </button>
                                </div>
                               
                                </Reveal>
                                
                            </fieldset>
                            <fieldset style={currentStep === 5 ? {}:{visibility:'hidden'}} className={currentStep === 5? 'select-cat':''}>
                            <Reveal effect="slide-in-right">
                                <div className="mb-3 row">
                                { formValues.map((formValue, index) => (
                                      <div className="col-12 col-md-3 col-sm-3" key={index}>
                                    <label  className={`radio ${index === 0 ? 'button--ujarak' : ''}`} htmlFor={"industry_"+formValue.id}>
                                            <input  type='radio' name="industry" id={"industry_"+formValue.id} autoComplete="off" value={formValue.id}  
                                            {...register('industry', {required: true, onChange: (e) =>{
                                                IndustryHandler(e.target.value)
                                           }})}
                                             />
                                                <span>{formValue.industry}</span>
                                                
                                            </label>
                                        </div>
                                ))}
                                 {errors?.industry &&  <div className="text-danger">{errors.industry.message}</div>}
                                 </div>
                                 <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(4)}>Back
                                    <span><i className="bi bi-arrow-left"></i></span>
                                </button>
                                {/* <button type="button" className="next submit action-button" onClick={()=>goToStep(6)}>Next
                                    <span><i className="bi bi-arrow-right"></i></span>
                                </button> */}
                              
                               
                                </Reveal>
                                
                            </fieldset>
                            <fieldset style={currentStep === 6? {}:{visibility:'hidden'}} className={currentStep === 6? 'select-cat ':''}>
                            <Reveal effect="slide-in-right">
                                <div className="mb-3  row">
                                { subIndustry && subIndustry.map((subInd, index) => (
                                        <div className="col-12 col-md-3 col-sm-3" key={index}>
                                                    <label  className="radio" htmlFor={"sub_industry_"+subInd.id}>
                                                    <input  type='radio' name="sub_industry" id={"sub_industry_"+subInd.id} autoComplete="off" value={subInd.id} 
                                                    {...register('sub_industry', {required: true})} />
                                                <span>{subInd.industry}</span>
                                                
                                            </label>
                                        
                                        </div>
                                ))}
                                  {errors?.sub_industry &&  <div className="text-danger">Please select industry</div>}
                                  </div>

                                  <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(5)}>Back
                                    <span><i className="bi bi-arrow-left"></i></span>
                                </button>
                                <button type="button" className="next submit action-button" onClick={()=>goToStep(7)}>Next
                                    <span><i className="bi bi-arrow-right"></i></span>
                                </button>
                               
                             
                                </Reveal>
                               
                            </fieldset>
                            <fieldset style={currentStep === 7 ? {}:{ visibility:'hidden' }} className={currentStep === 7 ? 'select-cat':''}>
                            <Reveal effect="slide-in-right">
                                <div className="mb-3 row">
                                { technologies && technologies.map((technology, index) => (
                                        <div className="col-12 col-md-3 col-sm-3" key={index}>
                                                    <label className="radio" htmlFor={"technology_"+technology.id}>
                                                    <input  type='checkbox' name="technology" id={"technology_"+technology.id} autoComplete="off" value={technology.id} 
                                                    {...register('technology', {required: true})} />
                                                <span>{technology.name}</span>
                                                
                                            </label>
                                        
                                        </div>
                                ))}
                                  {errors?.technology &&  <div className="text-danger">Please select technology</div>}
                                  </div>
                                  <button type="button" className="back previous action-button btn-prev" onClick={()=>goToStep(6)}>Back
                                    <span><i className="bi bi-arrow-left"></i></span>
                                </button>
                                 <button type="submit" className="next submit action-button" id="submit">Submit
                                    <span><i className="bi bi-arrow-right"></i></span>
                                </button>
                                </Reveal>
                            </fieldset>
                        </form>
                    </div>
                </div>
                </div>
                </DefaultLayout>
    )
}