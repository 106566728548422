import React, { useEffect, useState } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { setPageTitle } from "../store/resourceSlice";
import { useDispatch } from "react-redux";
import { postData } from "../services/HttpServices";
import { Link } from "react-router-dom";
import CreateProject from "./CreateProject";
import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";
export default function Projects() {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [isFull, setShowFull] = useState(false);
  const [message, setMessage] = useState('Loading...');
  

  const getProjects = () => {
    postData("/projects").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.status === 1) {
          setProjects(responseData.data.projects);
          if(responseData.data.projects.length === 0) {
          setMessage('No projects found')
          }
        }
      }
    });
  };

  useEffect(() => {
    dispatch(setPageTitle("Projects"));
    getProjects();
    // eslint-disable-next-line
  }, [dispatch]);

  const [toggleSection, setToggleSection] = React.useState(false);
  const toggleDrawer = (operation, show = false) => {
    if (operation) {
      setToggleSection(operation);
    } else {
      if (show) {
        if (window.confirm("Are you sure, This can not be undone?")) {
          setToggleSection(operation);
          dispatch(setPageTitle("Projects"));
        }
      } else {
        setToggleSection(operation);
        dispatch(setPageTitle("Projects"));
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="create-new-float">
              {/* <Link to="/create-project"> <i className="bi bi-plus-circle-fill"></i>Create Project</Link> */}
              <Link to={"#"} type="button" onClick={() => toggleDrawer(true)}>
                {" "}
                <i className="bi bi-plus-circle-fill"></i>Create Project
              </Link>
            </div>
          </div>
        </div>

        <div
          className="row form-center"
          style={toggleSection ? { marginRight: "1267px" } : {}}
        >
          <div className="col-xl-12 col-md-12">
            <div className="table-display">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Website</th>
                    <th>Description</th>
                    <th>Requirements</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {projects.length ? (
                    projects.map((project, index) => (
                      <tr key={index}>
                        <td>#{index+1}</td>
                        <td>{project.project_name}</td>
                        <td>{project.website}</td>
                        <td>{project.description.substring(0, 50)}</td>
                        <td>{project.requirements.substring(0, 50)}</td>
                        <td className="link-col">
                          <Link
                            className="btn btn-primary btn-sm text-white"
                            to={"/project-details/" + project.id}
                          >
                            <i className="bi bi-arrow-right"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key={0}>
                      <td colSpan={6}>
                        <div className="alert alert-primary text-center rounded-pill">
                          {message}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Drawer
          BackdropProps={{ invisible: true }}
          anchor={"right"}
          open={toggleSection}
          //onClose={toggleDrawer(false)}
          className={
            isFull
              ? "fullwidthsection toggle-block createProject-block"
              : "toggle-block createProject-block"
          }
        >
          <Box sx={{ width: isFull ? 1 : 1267 }} role="presentation">
            <CreateProject
              backButton={(show) => toggleDrawer(false, show)}
              setIntialStepfn={(value) => setShowFull(value)}
            />
          </Box>
        </Drawer>
      </div>
    </DefaultLayout>
  );
}
