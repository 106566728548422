import React,{useState} from "react";
import Header from "../components/Header";
import QuestionSidebar from "../components/QuestionSidebar";
import Footer from "../components/Footer";

const QuestionsLayouts = ({ children, group_id, categoryDataHandler}) => {
    const [showSideBar, setShowSideBar] = useState(false);
    const showMenu = () => {
        setShowSideBar(!showSideBar)
    }
    return (
         <>
        <Header showMenu={showMenu}/> 
         <QuestionSidebar group_id={group_id} categoryDataHandler={categoryDataHandler}   showSideBar={showSideBar} />
         <main id="main">
          {children}
        </main> 
         <Footer />
        </>
    )
}

export default QuestionsLayouts;