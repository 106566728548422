import React, { useState } from "react";
import { postData } from "../services/HttpServices";
import { AlertSuccess, AlertError } from "../components/Alerts";
import { useNavigate, Link } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();

  //const [hideMain, SetHideMain] = useState(true)
  // const [showSection, SetShowSection] = useState(true)
  const [showOtpSection, SetShowOtpSection] = useState(false);

  const [mousePosition, setMousePosition] = React.useState({ top: 0, left: 0 });

  // const showNextSection = (section) =>{
  //     SetShowSection(true)
  //    // SetHideMain(true)
  // }

  const validateEmailHandler = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = event.currentTarget.email.value;
    postData("/auth", formData)
      .then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.status === 0) {
            AlertError(responseData.data.error);
            return;
          }
          localStorage.setItem("ai-temp", email);
          AlertSuccess(responseData.data.success);
          //SetShowSection(false)
          SetShowOtpSection(true);
        }
      })
      .catch((e) => {
        AlertError(e);
      });
  };
  const validateOtpHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    postData("/auth/verify", formData)
      .then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.status === 0) {
            AlertError(responseData.data.error);
            return;
          }
          localStorage.removeItem("ai-temp");
          localStorage.setItem("ai-token", responseData.data.token);
          AlertSuccess(responseData.data.success);
          navigate("/projects");
        }
      })
      .catch((e) => {
        AlertError(e);
      });
  };
  const resendToken = () => {
    postData("/auth", { email: localStorage.getItem("ai-temp") })
      .then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.status === 0) {
            AlertError(responseData.data.error);
            return;
          }
          AlertSuccess(responseData.data.success);
        }
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  React.useEffect(() => {
    const UserToken = localStorage.getItem("ai-token");
    if (UserToken) {
      const getCurrentUser = () => {
        postData("/auth/currentloggedin").then((response) => {
          if (response.status === 200) {
            let userData = response.data;
            if (userData.status === 1) {
              navigate("/projects");
            } else {
              localStorage.removeItem("ai-token");
              AlertError(userData.data.error);
            }
          }
        });
      };
      getCurrentUser();
    }
    document.body.classList.add("login-bg");

    const updateMousePosition = (ev) => {
      setMousePosition({ top: ev.clientX - 20, left: ev.clientY - 20 });
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [navigate]);

  return (
    <React.Fragment>
      <div
        className="cursor"
        style={{
          top: mousePosition.top + "px",
          left: mousePosition.left + "px",
        }}
      ></div>
      <section className="main-container">
        {/*   <div className={hideMain ? "big-circle stopanm":"big-circle"}>
                                <div className="icon-block" onClick={() => showNextSection("ai")}>
                                    <div className="radius">
                                        <span className="place"></span>
                                        <span className="border-animation ba1"></span>
                                        <span className="border-animation ba2"></span>
                                    </div>
                                    <h4>Artificial Intelligence</h4>
                                </div>
                                <div className={hideMain ? "icon-block slider-left ":"icon-block "} onClick={() => showNextSection("ai")}>
                                    <div className="radius">
                                        <span className="place"></span>
                                        <span className="border-animation ba1"></span>
                                        <span className="border-animation ba2"></span>
                                    </div>
                                    <h4>Speech Recognition</h4>
                                </div>
                                <div className={hideMain ? "icon-block slider-left":"icon-block"} onClick={() => showNextSection("ml")}>
                                    <div className="radius">
                                        <span className="place"></span>
                                        <span className="border-animation ba1"></span>
                                        <span className="border-animation ba2"></span>
                                    </div>
                                    <h4>Machine Learning</h4>
                                </div>
                                <div className={hideMain ? "icon-block slider-left":"icon-block "} onClick={() => showNextSection("vac")}>
                                    <div className="radius">
                                        <span className="place"></span>
                                        <span className="border-animation ba1"></span>
                                        <span className="border-animation ba2"></span>
                                    </div>
                                    <h4>Virtual Assistant Chatbot</h4>
                                </div>
                            </div>
                          <div className="center-logo">
                                <img src="/images/ai-brain.png" alt=''/>
                            </div> */}
        {/* <!------------ Form email Section ------------> */}
        <div className="login-page">
          <div className="row">
            <div className="col-md-6 welcome-block d-flex justify-content-center">
              <div className="ai-image">
                <img src="/images/ai-image.png" alt="" />
              </div>
            </div>

            <div className="col-md-6 form-block d-flex justify-content-center">
              <div className="form-left py-5 px-5">
                <div
                  className={
                    showOtpSection === false
                      ? "form-section email-section showform"
                      : "form-section email-section hide"
                  }
                >
                  <form onSubmit={validateEmailHandler}>
                    <div className="mb-3">
                      <label className="form-label">Email address</label>
                      <small>
                        A verification code will be sent to the email address
                        your provide
                      </small>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter Your Email id."
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary email-btn sub-btn"
                    >
                      Submit
                      <img src="/images/next-arrow.png" alt="" />
                    </button>
                  </form>
                </div>
                {/* <!------------ Form OTP Section ------------> */}
                <div className="form-left py-5 px-5">
                <div
                  className={
                    showOtpSection === true
                      ? "form-section verification-section show-otp "
                      : "form-section verification-section hide"
                  }
                >
                  <form onSubmit={validateOtpHandler}>
                    <div className="mb-3">
                      <label className="form-label">Check Your Code</label>
                      <small>
                        Please enter 4-digit verification code that was sent.
                      </small>
                      <input
                        type="text"
                        className="form-control"
                        id="otp"
                        name="otp"
                        placeholder="Verification Code"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary sub-btn">
                      Submit <img src="/images/next-arrow.png" alt="" />{" "}
                    </button>
                    <p>
                      <Link
                        className="btn btn-link resend-btn"
                        onClick={resendToken}
                        href="/#"
                      >
                        Resend OTP
                      </Link>
                    </p>
                  </form>
                </div>
                </div>
                {/* <!------------ //Form OTP Section ------------> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!------------ //Form email Section ------------> */}
      </section>
    </React.Fragment>
  );
}
