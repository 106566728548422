import React, { useEffect, useState } from "react"
import { postData } from "../services/HttpServices";
import { Link, useNavigate } from "react-router-dom";
import { AlertError, AlertSuccess } from "./Alerts";
import { useDispatch,useSelector } from "react-redux";
import { setProfileData } from "../store/resourceSlice";

const Header = ({showMenu}) => {
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentData = useSelector((state) => state.aidata);
        /*Inital Stage*/
    const [userName, setUserName] = useState("");
   
    const logOut = () => {
        postData('/auth/logout', {}).then((response) => {
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    localStorage.removeItem('ai-token')
                    AlertSuccess(responseData.data.success)
                    return navigate('/')
                
                }else{
                    AlertError(responseData.data.error)
                }
            }
        })
    }
    
    useEffect(() => {
        postData('/auth/currentloggedin').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    setUserName(responseData.data.first_name)
                    dispatch(setProfileData(responseData));
                }else{
                    return navigate('/')
                }
            }
        })
    }, [navigate,dispatch]);
    return (
        <header id="header" className="header fixed-top d-md-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between" id="logo">
            <Link to="/projects" className="logo d-flex align-items-center">
                <img src="/images/logo-white.png" alt="" />
            </Link>
            {showMenu &&
                <Link type="button" onClick={()=>showMenu()} className="d-flex align-items-center toggleMenu">
                    <img src="/images/menu.png" alt="" />
                </Link>
            }
        </div>
        <nav className="header-nav">
            <h1 className="pagetitle">{currentData.pageTitle}</h1>
            <ul className="">
                <li className="nav-item dropdown pe-3">
                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/" data-bs-toggle="dropdown">
                    <img src="/images/user.png" alt="" className="user-img" />
                        <span className="d-none d-md-block dropdown-toggle ps-2">
                        {userName ? userName  : 'Anonymous'}
                        </span>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li>
                            <Link className="dropdown-item d-flex align-items-center" to="/">
                                <i className="bi bi-person"></i>
                                <span>My Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item d-flex align-items-center" onClick={logOut} href="/#">
                                <i className="bi bi-box-arrow-right"></i>
                                <span>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </header>
    )
}
export default Header;