
import { createSlice } from '@reduxjs/toolkit';
const groupsSlice = createSlice({
    name: 'rkmai',
    initialState: {
        groups: [],
        question:{},
        information:{},
        technologies:{},
        reportView:[]
    },
    reducers: {
        setGroupData(state, action) {
            state.groups = action.payload;
        },
        setQuestion(state, action) {
            state.question = action.payload;
        },
        setInformation(state, action) {
            state.information = action.payload;
        },
        setTechnologies(state, action) {
            state.technologies = action.payload;
        },
        setReportView(state, action) {
            state.reportView = [...state.reportView, action.payload];
        }
    }
})

export const { setGroupData, setQuestion,setInformation,setTechnologies,setReportView} = groupsSlice.actions;
export default groupsSlice.reducer;