import React from "react"
import { Link } from "react-router-dom";
const Footer = () => {
    // const [selectedTheme, SetSelectedTheme] = useState(false)
    // const SetThemeHandler = (e)=>{
    //     if(e.target.checked){
    //         document.body.classList.add('body-mode')
    //         localStorage.setItem('theme',true)
    //         SetSelectedTheme(true)
    //     }else{
    //         document.body.classList.remove('body-mode')
    //         localStorage.setItem('theme',false)
    //         SetSelectedTheme(false)
    //     }
    // }

    //useEffect(()=>{
        //const theme =  localStorage.getItem('theme')
        // if(!theme){
        //     document.body.classList.remove('body-mode')
        //     SetSelectedTheme(true)
        // }else{
        //     document.body.classList.add('body-mode') 
        //     SetSelectedTheme(false)
        // }

   // },[])

    return(
        <footer id="footer" className="footer">
        <div className="credits">
            Designed by <Link href="/">RKM Software</Link>
        </div>
{/* <div className="change-mode">
    <input id="mode-toggle" className="mode-toggle" type="checkbox" defaultChecked={selectedTheme === 'dark' ? true: false}  onChange={(e)=> SetThemeHandler(e)}/>
</div> */}





    </footer>
)
}
export default Footer;