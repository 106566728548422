import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
//import { useSelector } from 'react-redux';

const DefaultLayout = ({ children,showQuestionBar=true }) => {

   // const currentData = useSelector((state) => state.aiData);
    return (
         <>
        <Header /> 
       
        {showQuestionBar && <Sidebar /> }
         <main id="main">
          {children}
        </main> 
        {showQuestionBar && <Footer /> }
        </>
    )
}

export default DefaultLayout;