import React,{useEffect} from "react"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const QuestionSidebar = ({ ...props }) => {
    const groupsData = useSelector((state) => state.groupsData);

    useEffect(()=>{
        document.body.classList.remove('next-pre-page')
        document.body.classList.remove('create-page')
        document.body.classList.add('dashboard')
        document.body.classList.remove('login-bg')
    },[])
    

    return(

    <React.Fragment>
        <aside id="sidebar" className={props.showSideBar  ? "sidebar toggleBar" :"sidebar"}>
        <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item mandatory-col">
              { /* <Link className="nav-link active" to={"/"}>
                    <i className="bi bi-journal-text"></i><span>Mandatory</span>
                </Link> */}
                <ul className="nav-content">
                { groupsData.groups.map((group, index) => (
                    group.is_visible ?
                    <li key={index}>
                        <Link type="button"  onClick={()=> props.categoryDataHandler(group.id)} className={group.id=== props.group_id ? "active" : ""}>
                            <i className="bi bi-record-circle-fill"></i><span>{group.group_name} ({group.tot_ques}) </span>
                        </Link>
                        
                    </li>:""
                    
                ))}
                {/* <li>
                    <Link to={"/"} className="dropdown-toggle dropdown-toggle-right" id="subgroupsmenu" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-record-circle-fill"></i><span>More</span>
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="subgroupsmenu">
                        <li><Link class="dropdown-item" to={"/"}><i className="bi bi-record-circle-fill"></i><span>Action</span></Link></li>
                    </ul>
                    </li> */}
                </ul>
            </li>
        </ul>
       
    </aside>
        
    </React.Fragment>
     
)
}
export default QuestionSidebar;
