import React, { useEffect } from "react"
const Sidebar = () => {
    useEffect(()=>{
        document.body.classList.add('next-pre-page')
        document.body.classList.add('create-page')
        document.body.classList.remove('dashboard')
        document.body.classList.remove('login-bg')
    },[])
    return(
        <aside id="sidebar" className="sidebar">
        </aside>
)
}
export default Sidebar;
