import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AlertSuccess = (message) => {
    toast.success(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });
}

export const AlertError = (message) => {
    toast.error(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });
}
export const AlertWarning = (message ) => {
    toast.warn(message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });
}
export const Msg = ({ title, text }) => {
    return (
      <div className="msg-container">
        <p className="msg-title">{title}</p>
        <p className="msg-description">{text}</p>
      </div>
    );
  };

export const customAlert = (message, endQuestionProcess,getGroups) => {
    toast.warn((
        <div className="msg-container">
          <p className="msg-description">{message}</p>
          <button className="btn btn-danger btn-sm mx-2" onClick={()=> endQuestionProcess()}>End</button>
          <button className="btn btn-primary btn-sm" onClick={()=> getGroups()}>Continue</button>
        </div>
      ), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    });
}