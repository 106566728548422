import React,{useEffect,useState} from "react"
import QuestionsLayouts from "../layouts/QuestionsLayouts"
import { setPageTitle } from "../store/resourceSlice";
import { setGroupData,setQuestion,setInformation,setTechnologies } from "../store/groupsSlice";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import { AlertError, AlertSuccess,AlertWarning } from "../components/Alerts"
import { postData,postMultiPartData } from "../services/HttpServices";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useForm } from 'react-hook-form';
import { Hourglass } from 'react-loader-spinner'

export default function ProjectDetails(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const groupsData = useSelector((state) => state.groupsData);
    const { register, handleSubmit, formState: { errors },setValue} = useForm();
    const [answerText, SetAnswerText] = useState("")
    const [importantNote, SetImportantNote] = useState("")
    const [isLoading, SetIsLoading] = useState(false)
    const { id } = useParams()

    const getGroups=(params=null,category_id=null, type="")=>{
        const me = Object.create(params);
        me.project_id = id
        if(category_id)
             me.group_id = category_id
        else
            me.group_id = groupsData.question.group_id ?? ""

        me.type = type
        
     
       //return
        postData('/projects/questions',me).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    if(responseData.data.error){
                        AlertWarning(responseData.data.error)
                        navigate("/projects");
                        return
                    }
                    if(responseData.data.important){
                        SetImportantNote(responseData.data.important)
                    }
                    dispatch(setGroupData(responseData.data.group))
                    dispatch(setQuestion(responseData.data.question))
                    dispatch(setInformation(responseData.data.information))
                    dispatch(setTechnologies(responseData.data.technology))
                    
                }else if(responseData.status === 3){
                     dispatch(setGroupData([]))
                     dispatch(setQuestion({}))
                     dispatch(setInformation({}))
                     dispatch(setTechnologies({}))
                     return navigate('/projects/view/'+id)
                }else{
                    AlertWarning(responseData.data.error)
                    navigate("/projects");
                    return
                }
            }
            
        })
    }
    const categoryDataHandler = (category_id) =>{
        getGroups(null,category_id,'force')
    }

    const recorderControls = useAudioRecorder(
        {
          noiseSuppression: true,
          echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
      );
    const addAudioElement = async (blob) => {
        SetIsLoading(true)
        const audioBlob = new Blob([blob], { 'type' : 'audio/wav' });
        var blobFile = new File([audioBlob], 'audio_data.wav', {
            type:' audio/wav'
        });
        const toWav = require('audiobuffer-to-wav')
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const arrayBuffer = fileReader.result
            audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
                const wav = toWav(audioBuffer)
                const audio_data = new window.Blob([ new DataView(wav) ], {
                  type: 'audio/wav'
                })
                var formData=new FormData();
                formData.append("audio_data",audio_data)
                formData.append("project_id",id)
                formData.append("question_id",groupsData.question.question_id)

                postMultiPartData('/question/saveaudio',formData).then((response)=>{
                    SetIsLoading(false)
                    if(response.status === 200){
                        let responseData = response.data
                        if(responseData.status === 0){
                            AlertError(responseData.data.error);
                            return
                        }
                        AlertSuccess(responseData.data.success)
                        SetAnswerText(responseData.data.audio_text)
                        setValue('answer',responseData.data.audio_text)
                    }
                })
            })
        }
        
        fileReader.readAsArrayBuffer(blobFile)
        
    };

    const endQuestionProcess = () =>{
        postData('/projects/submit',{project_id:id}).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    return navigate('/projects/view/'+id)
                }else{
                    AlertError(responseData.data.error)
                    navigate("/projects");
                }
            }
            
        })
             
    }
    const onSubmit = data => {
        SetIsLoading(true)
        const formData = new FormData();
        if(data.upload_file.length > 0){
            formData.append("upload_file", data.upload_file[0]);
        }
       
        formData.append("answer", data.answer);
        formData.append("project_id", id);
        formData.append("question_id", groupsData.question.question_id);
        formData.append("source", groupsData.question.source);
        
        postMultiPartData('/question/saveanswer',formData).then((response)=>{
            SetIsLoading(false)
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 0){
                    AlertError(responseData.data.error);
                    return
                }
                AlertSuccess(responseData.data.success)
                setValue('answer',"")
                getGroups()
            }
        })
    }
    useEffect(() => {
       
        if(!id){
            AlertWarning('Please select a project')
            navigate("/projects");
            return
        }
        getGroups()
        dispatch(setPageTitle("Project Details"));
        // eslint-disable-next-line
    }, [dispatch,id]);
    return(
       
        <QuestionsLayouts group_id={groupsData.question.group_id} categoryDataHandler={categoryDataHandler}>
        <div className="container-fluid">
            <div className="contant-section">
                <div className="queans">
                    <div className="row form-center justify-content-center align-items-center">
                        <div className="col-xl-10 col-md-10 d-flex flex-column align-items-center justify-content-center">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {groupsData.question.question &&
                                <fieldset>
                                    <legend><span className="badge text-bg-success text-white">{(groupsData.question.group_name).toUpperCase()}</span> </legend>
                                    <legend><span className="badge text-bg-primary text-white">{(groupsData.question.source).toUpperCase()}</span> {groupsData.question.question}  </legend>
                                    <div className="mb-3">
                                        <textarea className="form-control" id="answer" name="answer" placeholder="Write something here" defaultValue={answerText}  {...register("answer", {required: true})}></textarea>
                                        {errors?.answer &&  <div className="text-danger">Please type your answer</div>}
                                    </div>
                                    <div className="col-12 attachments-section">
                                           <div className="row" style={!isLoading ? {visibility:'visible'} : {visibility:'hidden'}}>
                                          
                                            <ul>
                                                <li> 
                                                    <div className="documentupload">
                                                    <label htmlFor="file-input">
                                                <img src="/images/paper-clip.png" alt=""/>
                                                </label>
                                                <input type="file" name="upload_file" id="file-input" {...register("upload_file", {required: false})}/>
                                            </div>
                                            </li>
                                            <li> <AudioRecorder
                                                    onRecordingComplete={(blob) => addAudioElement(blob)}
                                                    recorderControls={recorderControls}
                                                    //downloadOnSavePress={true}
                                                    downloadFileExtension="wav"
                                                    showVisualizer={true}
                                                /></li>
                                            </ul>
                                        </div>
                                        <div className="col-12">
                                        <div className="row">
                                        <ul>
                                                <li>
                                                {isLoading ? 
                                            <Hourglass
                                                visible={true}
                                                height="40"
                                                width="40"
                                                ariaLabel="hourglass-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="float-end"
                                                colors={['#306cce', '#c3c3e8']}
                                                />
                                              : <button className="next action-button btn-next"  type="submit">Send</button>
                                        }
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                        
                                        </div>
                                </fieldset>
                                }
                            </form>
                            {importantNote &&
                            <fieldset>
                            <div className="alert alert-primary fs-6 fst-italic" role="alert">
                               {importantNote}
                            </div>
                             <button className="btn btn-danger btn-sm mx-2" onClick={()=> endQuestionProcess()}>Submit Form</button>
                             
                            </fieldset>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </QuestionsLayouts>
    )
}