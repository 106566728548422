import React,{useEffect,useState} from "react"
import DefaultLayout from "../layouts/DefaultLayout"
import { setPageTitle } from "../store/resourceSlice";
import { setReportView } from "../store/groupsSlice";
import { useDispatch } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import { AlertError, AlertWarning,AlertSuccess } from "../components/Alerts"
import { postData, postMultiPartData } from "../services/HttpServices";
export default function ProjectOverView(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()
    const [quizlist, setQuizlist] = useState([]);

    const [answerID, setAnswerID] = useState(0);
    const [answerTerm, setAnswerTerm] = useState();
    const [isDisabled, setIsDisabled] =useState(true);
    const [showProgess, setShowProgess] =useState(false);

    const getQuestions=()=>{
        postData('/projects/submit',{project_id:id}).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    setQuizlist(responseData.data.project.quiz_list)
                }else{
                    AlertError(responseData.data.error)
                    navigate("/projects");
                }
            }
            
        })
    }


    
    const submitHandler = () => {
        postData('/projects/submit',{project_id:id,action:"submit"}).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 1){
                    dispatch(setReportView(responseData.data.report_data));
                    return navigate("/projects/report/"+id)
                }else{
                    AlertWarning(responseData.data.error)
                    setShowProgess(true)
                }
            }
            
        })
    }
    
    useEffect(() => {
        if(!id){
            AlertWarning('Please select a project')
            navigate("/projects");
            return
        }
        dispatch(setPageTitle("Preview Responses"));
        getQuestions()
        // eslint-disable-next-line
    }, [dispatch,id]);

    const changeDisableState = () =>{
        setIsDisabled(!isDisabled)
    }

   
    React.useEffect(() => {
        if(showProgess){
            setTimeout(() => { 
                submitHandler()
            }, 20000);
        }
        // eslint-disable-next-line
      }, [showProgess]);

    useEffect(() => {

        if(!answerTerm || isDisabled === true){
            return;
        }
        const delayDebounceFn = setTimeout(() => {
          const formData = new FormData();
          formData.append("answer", answerTerm);
          formData.append("answer_id", answerID);
          const quizObj = quizlist.filter(function (quiz) { return quiz.answer_id === answerID })
          if(!quizObj.length){
            return;
          }
          formData.append("source", quizObj[0].source);
          formData.append("question_id", quizObj[0].question_id);
          formData.append("project_id", id);
          
          postMultiPartData('/question/saveanswer',formData).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                if(responseData.status === 0){
                    AlertError(responseData.data.error);
                    return
                }
                AlertSuccess(responseData.data.success)
            }
        })
          // Send Axios request here
        }, 3000)
    
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line
      }, [answerTerm,answerID])
    return(
        <DefaultLayout>
            <div className="container-fluid">
            <div className="contant-section">
                <div className="queans">
                    <div className="row form-center justify-content-center align-items-center">
                        <div className="col-xl-12 col-md-12">
                          {showProgess ?
                               <div className="overviewbar">
                                    <div className="in"><span>Please Wait..</span></div>
                                </div>
                        :
                          <React.Fragment>
                          { quizlist && quizlist.map((quiz, index) => (
                                    <div className="row "  key={index}>
                                  <fieldset>
                                  <legend><span className="badge text-bg-success text-white fst-italic">{(quiz.group_name).toUpperCase()}</span> </legend>
                                    <legend><span className="badge text-bg-primary text-white fst-italic">{(quiz.source).toUpperCase()}</span> {quiz.question}</legend>
                                    <div className="mb-3">
                                        <textarea className="form-control" id={"answer_"+quiz.answer_id} disabled={isDisabled} placeholder="Write something here" defaultValue={quiz.answer} 
                                        onChange={(e) => {
                                            setAnswerTerm(e.target.value)
                                            setAnswerID(quiz.answer_id)
                                        }}
                                        ></textarea>
                                    </div>
                                </fieldset>
                                </div>
                                 ))
                                }
                                 { quizlist.length > 0 ?
                                <div className="submitdiv">
                                    <button type="button" className="next submit action-button" id="disabledButton" onClick={()=> changeDisableState()}>{isDisabled ? <span> Edit Responses <i className="bi bi-pen"></i> </span>:  <span> Cancel <i className="bi bi-arrow-clockwise"></i> </span> }
                                    </button>
                                    <button type="button" className="next submit action-button" id="button" onClick={()=> submitHandler()}>Submit
                                        <span><i className="bi bi-arrow-right"></i></span>
                                    </button>
                                    </div>
                                :
                                <div className="alert alert-info text-center">Loading....</div>
                                }
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </DefaultLayout>
    )
}