
import { createSlice } from '@reduxjs/toolkit';
const rkmSlice = createSlice({
    name: 'rkmai',
    initialState: {
        profile: [],
        pageTitle:''
    },
    reducers: {
        setPageTitle(state, action) {
            document.title = 'RKM AI | '+action.payload
            state.pageTitle = action.payload
        },
        setProfileData(state, action) {
            state.profile = action.payload
        }
    }
})

export const { setPageTitle,setProfileData,} = rkmSlice.actions;
export default rkmSlice.reducer;