
import React from 'react';

import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store/store';
/*pages*/
import Login from './pages/Login';
import ProjectDetails from './pages/ProjectDetails';
import Projects from './pages/Projects';
import CreateProject from './pages/CreateProject';
import ProjectOverView from './pages/ProjectOverView';
import ProjectReport from './pages/ProjectReport';
function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
        <Router>
          <Routes>
              <Route path="/" exact={true} Component={Login} />
             <Route path="/projects" exact={true} Component={Projects} />
              <Route path="/project-details/:id" exact={true} Component={ProjectDetails} />
              <Route path="/create-project" exact={true} Component={CreateProject} />
              <Route path="/projects/view/:id" exact={true} Component={ProjectOverView} />
              <Route path="/projects/report/:id" exact={true} Component={ProjectReport} />

          </Routes>
      </Router>
    </Provider>
  );
}

export default App;
